<!--权限控制-行政区划-->
<template>
<section>
  <!-- 工具条 -->
  <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
  <el-form :inline='true' :model='filter'>
    <el-form-item>
      <el-input size='mini' v-model='filter.name' @keyup.native.enter="getData" placeholder='关键字搜索'>
        <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
        <el-button slot='append' icon='el-icon-search' @click='getData'></el-button>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
      <el-button type='primary' size='mini' @click='handleSetting'>参数设置</el-button>
    </el-form-item>
  </el-form>
  </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist.content' size='mini' highlight-current-row border @selection-change='selsChange' style='width: 100%' :max-height='clientHeight'>
        <el-table-column type='selection' />
        <el-table-column type='index' label='#' width='55' align='center' />
        <el-table-column prop='Typ' label='层级类型' align='center' header-align='center'>
          <template slot-scope='scope'>
            <span v-html="statusFormatter(scope.row.Typ)"></span>
          </template>
        </el-table-column>
        <el-table-column prop='Name' label='名称' :show-overflow-tooltip='true' align='center' header-align='center' />
        <el-table-column prop='Lng' label='经度' align='center' header-align='center'  />
        <el-table-column prop='Lat' label='纬度' align='center' header-align='center' />
        <el-table-column prop='Samplings' label='抽样次数' align='center' header-align='center' />
        <el-table-column prop='OfflineJudge' label='异常判定' align='center' header-align='center' />
        <el-table-column prop='EmissionsSill' label='油烟阈值' align='center' header-align='center' />
        <!-- <el-table-column prop='Status' label='状态' width='120' align='center' header-align='center' /> -->
        <el-table-column label='操作' align='center' header-align='center'>
           <template slot-scope='scope'>
           <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit' />
           <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete' />
           </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-button type='danger' size='mini' @click='bulkRemove' :disabled='sels.length===0'>批量删除</el-button>
      <el-pagination small background @size-change='handleSizeChange' @current-change='handleCurrentChange' :current-page.sync='filter.page' :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper' :total='datalist.total' style='display:inline-block;margin-left:15px'>
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' @close='handlerClose' width="40%">
      <template v-if="dlg.typ===1">
        <el-form :model='formData' label-width='80px' :rules='formRule' ref='form' size='mini'>
          <el-col :span='12'>
          <el-form-item prop='Name' label='名称' >
            <el-input @change='hasChange' v-model.trim='formData.Name' />
          </el-form-item>
          <el-form-item prop='Pid' label='父节点' >
            <el-cascader
              placeholder="关键字"
              v-model.trim='formData.Pid'
              @change='handleCascaderChange'
              :options="areaTree"
              :props="props"
              filterable />
          </el-form-item>
          <el-form-item prop='Typ' label='类型层级' >
            <el-select  @change='hasChange' v-model="formData.Typ" placeholder="请选择">
              <el-option label="全国" :value="0" />
              <el-option label="省级" :value="1" />
              <el-option label="市级" :value="2" />
              <el-option label="区县" :value="3" />
              <el-option label="街道" :value="4" />
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span='12'>
          <el-form-item prop='Lng' label='经度' >
            <el-input style="with: 120px" placeholder="可直接粘贴到此处" @change="hasChange" v-model.trim='formData.Lng' @keyup.ctrl.86.native="handlePaste" />
          </el-form-item>
          <el-form-item prop='Lat' label='纬度' >
            <el-input style="with: 120px" @change="hasChange" v-model.trim='formData.Lat' />
          </el-form-item>
          <a style="color: #409EFF;text-decoration: none; margin-left: 40px" target="_blank" href="https://api.map.baidu.com/lbsapi/getpoint/index.html">百度坐标拾取系统</a>
          </el-col>
        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
          <el-button type='primary' size='mini' @click.native='handleSubmit' >提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form label-width='80px' size='mini'>
          <el-form-item prop='Pid' label='指定区域' >
            <el-cascader
              placeholder="关键字"
              @change='handleAreaChange'
              v-model.trim='formData.Id'
              :options="areaTree"
              :props="props"
              filterable />
          </el-form-item>
          <el-form-item label='报警阈值' >
            <el-input-number v-model.trim='formData.EmissionsSill' />
          </el-form-item>
          <el-form-item label='抽样次数' >
            <el-input-number v-model.trim='formData.Samplings' />
          </el-form-item>
          <el-form-item label='异常判定' >
            <el-input-number v-model.trim='formData.OfflineJudge' />
          </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
          <el-button type='primary' size='mini' @click.native='handleSettingSubmit' >提交</el-button>
        </div>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { statusFormatter, join, loadData, toIntArray } from '@/util/index'

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      datalist: {},
      setting: null,
      dlg: {
        visible: false,
        typ: null,
        title: ''
      },
      filter: {
        name: null,
        field: 'name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      formData: {},
      formRule: {
        Name: [{ required: true, message: '名称不可为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState({
      props: state => Object.assign({}, state.props, { checkStrictly: true })
    }),
    ...mapState(['area', 'areaTree', 'clientHeight'])
  },
  created() {
    this.getData()
  },
  methods: {
    statusFormatter(status) {
      return statusFormatter('AREA', status)
    },
    hasChange() {
      this.changeState = true
    },
    handleCascaderChange: function(value) {
      this.changeState = true
      if (value) this.formData.Typ = value.length
    },
    handlePaste() {
      this.changeState = true
      const arr = this.formData.Lng.split(',')
      this.formData.Lng = arr[0]
      this.formData.Lat = arr[1]
    },
    getData: function() {
      if (this.filter.name) {
        this.datalist.content = this.area.filter((v, _) => v.Name.indexOf(this.filter.name) !== -1)
        this.datalist.total = this.datalist.content.length
        this.$set(this.datalist, 'content', this.datalist.content.slice((this.filter.page - 1) * this.filter.size, this.filter.page * this.filter.size))
      } else {
        this.datalist.total = this.area.length
        this.$set(this.datalist, 'content', this.area.slice((this.filter.page - 1) * this.filter.size, this.filter.page * this.filter.size))
      }
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.name = null
      loadData(this, (res) => {
        this.$store.dispatch('setArea', res).then(() => {
          this.getData()
        })
      }, 2)
    },
    selsChange: function(sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function() {
      this.dlg.title = '新增'
      this.dlg.visible = true
      this.dlg.typ = 1
      this.formData = { 'Pid': [] }
    },
    handleEdit: function(index, row) {
      this.dlg.title = '编辑'
      this.dlg.visible = true
      this.dlg.typ = 1
      this.index = index
      this.formData = Object.assign({}, row)
      this.formData.Pid = row.Pid === '' ? [] : toIntArray(row.Pid)
    },
    handleAreaChange(v) {
      let arr = this.areaTree
      let j = 0
      const l2 = v.length
      for (let i = 0, l1 = arr.length; i < l1; i++) {
        if (arr[i].Id === v[j]) {
          if (j === l2 - 1) {
            this.formData.EmissionsSill = arr[i].EmissionsSill
            this.formData.Samplings = arr[i].Samplings
            this.formData.OfflineJudge = arr[i].OfflineJudge
            break
          } else {
            arr = arr[i].children
            j++
            i = -1
          }
        }
      }
    },
    handleSetting: function() {
      this.dlg.title = '参数设置'
      this.dlg.visible = true
      this.dlg.typ = 0
    },
    // 批量删除
    bulkRemove: function() {
      const ids = this.sels.map(item => item.Id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post('admin/bulkRemoveArea', { ids: ids }).then((res) => {
          this.getData()
        }).catch(() => {})
      }).catch(() => {})
    },
    handleDel: function(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const para = { id: row.Id }
        this.$get('admin/delArea', para).then(() => {
          this.handleRefresh()
        }).catch(() => {})
      }).catch(() => {})
    },
    handleSettingSubmit: function() {
      if (this.formData.Id === undefined) {
        this.$notify.warning({
          message: '请先指定区域'
        })
        return
      }
      this.$post('admin/sysParamSetting', {
        id: join(this.formData.Id),
        emissionsSill: this.formData.EmissionsSill,
        samplings: this.formData.Samplings,
        offlineJudge: this.formData.OfflineJudge
      }).then(req => {
        if (req) {
          this.dlg.visible = false
          this.handleRefresh()
        }
      })
    },
    handleSubmit: function() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.formData.Pid = join(this.formData.Pid)
            const para = Object.assign({}, this.formData)
            this.$post('admin/saveArea', para).then(req => {
              this.dlg.visible = false
            }).catch(() => {})
          }).catch(() => {})
        }
      })
    },
    handlerClose: function() {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      this.formData.Id = null
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    }
  }
}
</script>
